<template>
  <svg
    width="1600px"
    height="900px"
    viewBox="0 0 1600 900"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>path-waldorf-astoria</title>
    <defs>
      <filter
        x="-5.7%"
        y="-3.3%"
        width="111.4%"
        height="106.6%"
        filterUnits="objectBoundingBox"
        id="filter-2"
      >
        <feMorphology
          radius="3.00000012"
          operator="dilate"
          in="SourceAlpha"
          result="shadowSpreadOuter1"
        ></feMorphology>
        <feOffset
          dx="0"
          dy="0"
          in="shadowSpreadOuter1"
          result="shadowOffsetOuter1"
        ></feOffset>
        <feMorphology
          radius="3.00000012"
          operator="erode"
          in="SourceAlpha"
          result="shadowInner"
        ></feMorphology>
        <feOffset
          dx="0"
          dy="0"
          in="shadowInner"
          result="shadowInner"
        ></feOffset>
        <feComposite
          in="shadowOffsetOuter1"
          in2="shadowInner"
          operator="out"
          result="shadowOffsetOuter1"
        ></feComposite>
        <feGaussianBlur
          stdDeviation="5.5"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        ></feGaussianBlur>
        <feColorMatrix
          values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 1 0"
          type="matrix"
          in="shadowBlurOuter1"
        ></feColorMatrix>
      </filter>
      <radialGradient
        cx="50%"
        cy="50%"
        fx="50%"
        fy="50%"
        r="50%"
        gradientTransform="translate(0.5, 0.5), rotate(90), scale(1, 0.9806), translate(-0.5, -0.5)"
        id="radialGradient-3"
      >
        <stop stop-color="#FFFFFF" stop-opacity="0" offset="0%"></stop>
        <stop
          stop-color="#FFFFFF"
          stop-opacity="0.466182255"
          offset="100%"
        ></stop>
      </radialGradient>
      <path
        d="M14.399939,0 C9.09791629,0 4.80000019,4.20140023 4.80000019,9.38394479 C4.80000019,11.6930172 7.66412882,16.5600732 7.66412882,16.5600732 L14.399939,27.6000011 L20.7486549,16.5600732 C20.7486549,16.5600732 24.000001,11.6584597 24.000001,9.38394479 C24.000001,4.20140023 19.7019618,0 14.399939,0"
        id="path-4"
      ></path>
      <filter
        x="-101.6%"
        y="-70.7%"
        width="303.1%"
        height="248.3%"
        filterUnits="objectBoundingBox"
        id="filter-5"
      >
        <feMorphology
          radius="1"
          operator="dilate"
          in="SourceAlpha"
          result="shadowSpreadOuter1"
        ></feMorphology>
        <feOffset
          dx="0"
          dy="0"
          in="shadowSpreadOuter1"
          result="shadowOffsetOuter1"
        ></feOffset>
        <feGaussianBlur
          stdDeviation="5.5"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        ></feGaussianBlur>
        <feColorMatrix
          values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 1 0"
          type="matrix"
          in="shadowBlurOuter1"
        ></feColorMatrix>
      </filter>
    </defs>
    <g
      id="Casinos"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="path-waldorf-astoria">
        <g id="Route" stroke-linecap="round" stroke-linejoin="round">
          <path
            class="path-glow"
            fill="none"
            stroke="#FFFFFF"
            stroke-width="6"
            filter="url(#filter-2)"
            d="M864.200005,149.600001 L885.5,149.600001 L885.5,164.000002 L864.200005,164.000002 C864.200005,180.121989 864.200005,188.182983 864.200005,188.182983 C864.200005,188.182983 851.689713,188.182983 826.669128,188.182983 C826.669128,159.260995 826.669128,144.200001 826.669128,143.000001 C826.669128,141.800001 808.996562,141.800001 773.651428,143.000001 C773.651428,164.554261 773.651428,175.33139 773.651428,175.33139 C773.651428,175.33139 767.762878,179.615255 755.985779,188.182983 L755.985779,234.597443 L759.441956,251.539825 C752.403259,254.71933 737.550761,256.728221 714.88446,257.566498 L668.268555,257.566498 C690.180847,312.209137 690.725891,348.011993 690.725891,370.209961 C690.725891,385.008606 681.178548,421.849528 662.083862,480.732727 L657.017792,661.500977 L734,661.500977 C734,683.154012 734,693.98053 734,693.98053 C734,693.98053 665.015625,709.534149 665.015625,709.534149 C665.015625,709.534149 665.015625,707.340973 665.015625,702.95462 C584.071878,702.95462 543.600005,702.95462 543.600005,702.95462 C543.600005,702.95462 543.600005,736.400002 543.600005,736.400002 C543.600005,736.400002 546.800005,736.400002 553.200005,736.400002"
          ></path>
          <path
            class="path-line"
            stroke="#FFFFFF"
            stroke-width="6"
            fill="none"
            d="M864.200005,149.600001 L885.5,149.600001 L885.5,164.000002 L864.200005,164.000002 C864.200005,180.121989 864.200005,188.182983 864.200005,188.182983 C864.200005,188.182983 851.689713,188.182983 826.669128,188.182983 C826.669128,159.260995 826.669128,144.200001 826.669128,143.000001 C826.669128,141.800001 808.996562,141.800001 773.651428,143.000001 C773.651428,164.554261 773.651428,175.33139 773.651428,175.33139 C773.651428,175.33139 767.762878,179.615255 755.985779,188.182983 L755.985779,234.597443 L759.441956,251.539825 C752.403259,254.71933 737.550761,256.728221 714.88446,257.566498 L668.268555,257.566498 C690.180847,312.209137 690.725891,348.011993 690.725891,370.209961 C690.725891,385.008606 681.178548,421.849528 662.083862,480.732727 L657.017792,661.500977 L734,661.500977 C734,683.154012 734,693.98053 734,693.98053 C734,693.98053 665.015625,709.534149 665.015625,709.534149 C665.015625,709.534149 665.015625,707.340973 665.015625,702.95462 C584.071878,702.95462 543.600005,702.95462 543.600005,702.95462 C543.600005,702.95462 543.600005,736.400002 543.600005,736.400002 C543.600005,736.400002 546.800005,736.400002 553.200005,736.400002"
          ></path>
        </g>
        <g id="Ending-Point" transform="translate(538.8, 721)">
          <circle
            id="Oval-back"
            fill="url(#radialGradient-3)"
            cx="14.4000006"
            cy="14.6000011"
            r="14.4000006"
          ></circle>
          <circle
            id="Oval-back"
            fill="url(#radialGradient-3)"
            cx="14.4000006"
            cy="14.6000011"
            r="9.60000038"
          ></circle>
          <circle
            id="Oval"
            fill="#5A5A5A"
            cx="14.4000006"
            cy="14.6000011"
            r="4.80000019"
          ></circle>
        </g>
        <image
          id="Bitmap"
          x="413"
          y="670"
          width="157"
          height="141"
          href="@/assets/images/icons/end.png"
        ></image>
        <g id="Starting-Point" transform="translate(849.8, 122)">
          <circle
            id="Oval-back"
            fill="url(#radialGradient-3)"
            cx="14.4000006"
            cy="27.6000011"
            r="14.4000006"
          ></circle>
          <circle
            id="Oval-back"
            fill="url(#radialGradient-3)"
            cx="14.4000006"
            cy="27.6000011"
            r="9.60000038"
          ></circle>
          <circle
            id="Oval"
            fill="#5A5A5A"
            cx="14.4000006"
            cy="27.6000011"
            r="4.80000019"
          ></circle>
          <g id="Fill-1">
            <use
              fill="black"
              fill-opacity="1"
              filter="url(#filter-5)"
              xlink:href="#path-4"
            ></use>
            <use fill="#FFFFFF" fill-rule="evenodd" xlink:href="#path-4"></use>
          </g>
        </g>
        <text
          id="Waldorf-Astoria"
          font-family="ArtegraSans-Regular, Artegra Sans"
          font-size="14.4000006"
          font-weight="normal"
          line-spacing="15.6000006"
          fill="#FFFFFF"
        >
          <tspan x="902" y="144">Waldorf</tspan>
          <tspan x="902" y="159.600001">Astoria</tspan>
        </text>
        <rect
          id="Rectangle"
          fill="#000000"
          x="1599"
          y="899"
          width="1"
          height="1"
        ></rect>
        <rect
          id="Rectangle"
          fill="#000000"
          x="0"
          y="0"
          width="1"
          height="1"
        ></rect>
        <rect
          id="Rectangle"
          fill="#000000"
          x="1599"
          y="0"
          width="1"
          height="1"
        ></rect>
        <rect
          id="Rectangle"
          fill="#000000"
          x="0"
          y="899"
          width="1"
          height="1"
        ></rect>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "WaldorfAstoriaPath",
  mounted() {
    var path = document.querySelector(".path-line");
    var length = path.getTotalLength();
    path.style.transition = path.style.WebkitTransition = "none";
    path.style.strokeDasharray = length + " " + length;
    path.style.strokeDashoffset = length;
    path.getBoundingClientRect();
    path.style.transition = path.style.WebkitTransition =
      "stroke-dashoffset 2s ease-in-out";
    path.style.strokeDashoffset = "0";

    var pathGlow = document.querySelector(".path-glow");
    var lengthGlow = pathGlow.getTotalLength();
    pathGlow.style.transition = pathGlow.style.WebkitTransition = "none";
    pathGlow.style.strokeDasharray = lengthGlow + " " + lengthGlow;
    pathGlow.style.strokeDashoffset = lengthGlow;
    pathGlow.getBoundingClientRect();
    pathGlow.style.transition = pathGlow.style.WebkitTransition =
      "stroke-dashoffset 2s ease-in-out";
    pathGlow.style.strokeDashoffset = "0";
  },
};
</script>

<style lang="scss" scoped>
</style>